@import 'angularjs-slider/dist/rzslider';
@import './src/scss/variables';
@import './src/scss/app';
@import './src/scss/slider';

.widget__bpm {
  &-title {
    font-size: $font-size3;
  }

  &-slider {
    display: flex;
    align-items: center;
    gap: 1rem;
  }

  &-beats {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;

    &_list {
      display: flex;
      gap: .5rem;

      .beat {
        &.active {
          .fa-circle::before {
            content: "\f192";
          }

          &.first-beat {
            .fa-circle::before {
              content: "\e12d";
            }
          }
        }
      }
    }
  }
}

.image-list {
  max-width: 700px;
  margin: auto;

  .image-item {
    width: 4rem;
    height: 4rem;
    cursor: pointer;
    border-radius: 100%;

    &.active {
      background-color: $color6;
    }

    img.sub-image {
      max-width: 100%;
    }
  }
}

@import 'dark-theme';