@import './src/scss/variables';

.dark-theme {
  .image-list .image-item {
    &.active {
      background-color: $dark-color6;
		}

		img.sub-image {
      filter: invert(100%);
		}
  }
}